@import 'spinner.scss';

@keyframes toRight {
    30% {
        left: -44px;
        top: -48px;
    }

    60% {
        left: -40px;
        top: -50px;
    }
}

@keyframes toLeft {
    30% {
        left: -36px;
        top: -48px;
    }

    60% {
        left: -40px;
        top: -50px;
    }
}

@keyframes toTop {
    30% {
        left: -40px;
        top: -44px;
    }

    60% {
        left: -40px;
        top: -50px;
    }
}

@keyframes toDown {
    30% {
        top: -54px;
    }

    60% {
        top: -50px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes color-change {
    0% {
        border-top-color: themed('primary-success-text-color');
        border-right-color: themed('primary-success-text-color');
    }

    25% {
        border-top-color: themed('blue-color');
        border-right-color: themed('blue-color');
    }

    50% {
        border-top-color: themed('purple-color');
        border-right-color: themed('purple-color');
    }

    75% {
        border-top-color: themed('red-color');
        border-right-color: themed('red-color');
    }

    100% {
        border-top-color: themed('primary-success-text-color');
        border-right-color: themed('primary-success-text-color');
    }
}

@keyframes border-change {
    12% {
        border-top-color: themed('blue-color');
        border-right-color: themed('blue-color');
    }

    24% {
        border-right-color: themed('blue-color');
    }

    36% {
        border-right-color: themed('purple-color');
        border-bottom-color:  themed('purple-color');
    }

    48% {
        border-bottom-color:  themed('purple-color');
    }

    60% {
        border-bottom-color: themed('blue-color');
        border-left-color: themed('blue-color');
    }

    72% {
        border-left-color: themed('blue-color');
    }

    84% {
        border-left-color: themed('purple-color');
        border-top-color: themed('purple-color');
    }

    96% {
        border-top-color: themed('purple-color');
    }
}

@keyframes shadow-change {
    0% {
        box-shadow: 1px 0 1px 0 themed('blue-color');
    }

    25% {
        box-shadow: 1px 0 1px 0 themed('purple-color');
    }

    50% {
        box-shadow: 1px 0 1px 0 themed('purple-color');
    }

    75% {
        box-shadow: 1px 0 1px 0 themed('green-color');
    }

    100% {
        box-shadow: 1px 0 1px 0 themed('blue-color');
    }
}

@keyframes active-border-change {
    12% {
        border-top-color: themed('blue-color');
        border-width: 2px;
        border-right-color: themed('blue-color');
    }

    24% {
        border-right-color: themed('blue-color');
        border-width: 2px;
    }

    36% {
        border-right-color: themed('light-blue-color');
        border-bottom-color:  themed('light-blue-color');
        border-width: 2px;
    }

    48% {
        border-bottom-color:  themed('light-blue-color');
        border-width: 2px;
    }

    60% {
        border-bottom-color: themed('blue-color');
        border-left-color: themed('blue-color');
        border-width: 2px;
    }

    72% {
        border-left-color: themed('blue-color');
        border-width: 2px;
    }

    84% {
        border-left-color: themed('light-blue-color');
        border-width: 2px;
        border-top-color: themed('light-blue-color');
    }

    96% {
        border-top-color: themed('light-blue-color');
    }
}

@keyframes error-border-change {
    12% {
        border-top-color: themed('orange-color');
        border-width: 2px;
        border-right-color: themed('orange-color');
    }

    24% {
        border-right-color: themed('orange-color');
        border-width: 2px;
    }

    36% {
        border-right-color: themed('light-red-color');
        border-bottom-color:  themed('light-red-color');
        border-width: 2px;
    }

    48% {
        border-bottom-color:  themed('light-red-color');
        border-width: 2px;
    }

    60% {
        border-bottom-color: themed('orange-color');
        border-left-color: themed('orange-color');
        border-width: 2px;
    }

    72% {
        border-left-color: themed('orange-color');
        border-width: 2px;
    }

    84% {
        border-left-color: themed('light-red-color');
        border-width: 2px;
        border-top-color: themed('light-red-color');
    }

    96% {
        border-top-color: themed('light-red-color');
    }
}

@keyframes success-border-change {
    12% {
        border-top-color: themed('green-color');
        border-width: 2px;
        border-right-color: themed('green-color');
    }

    24% {
        border-right-color: themed('green-color');
        border-width: 2px;
    }

    36% {
        border-right-color: themed('blue-color');
        border-bottom-color:  themed('blue-color');
        border-width: 2px;
    }

    48% {
        border-bottom-color:  themed('blue-color');
        border-width: 2px;
    }

    60% {
        border-bottom-color: themed('green-color');
        border-left-color: themed('green-color');
        border-width: 2px;
    }

    72% {
        border-left-color: themed('green-color');
        border-width: 2px;
    }

    84% {
        border-left-color: themed('blue-color');
        border-width: 2px;
        border-top-color: themed('blue-color');
    }

    96% {
        border-top-color: themed('blue-color');
    }
}

@keyframes shadow-change-center {
    0% {
        box-shadow: 0 0 3px 0 themed('blue-color');
    }

    20% {
        box-shadow: -1px 0 3px 0 themed('purple-color');
    }

    40% {
        box-shadow: -1px 1px 3px 0 themed('green-color');
    }

    60% {
        box-shadow: 0 1px 3px 0 themed('blue-color');
    }

    80% {
        box-shadow: 1px 1px 3px 0 themed('purple-color');
    }

    100% {
        box-shadow: 1px 0 3px 0 themed('green-color');
    }
}

@keyframes circle-color-change {
    0% {
        border-color: themed('blue-color');
    }

    25% {
        border-color: themed('purple-color');
    }

    50% {
        border-color: themed('purple-color');
    }

    75% {
        border-color: themed('green-color');
    }

    100% {
        border-color: themed('blue-color');
    }
}
