.mdc-dialog__container {
    .mdc-dialog__surface {
        overflow-y: unset;
    }
}

.ui-modal-header-container {
    display: flex;
    flex-flow: column;
    max-height: 70px;
    width: 100%;
    padding: 5px 10px;

    .ui-header-container .ui-header-title-container .ui-header-title {
        font-size: 15px;
    }
}
.ui-modal-body-container {
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    padding: 10px;
    width: 100%;
}

.ui-modal-footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
