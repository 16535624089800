.ui-icon {
    font-size: 15px;
}

.ui-icon-regular {
    @include themify {
        color: themed('gray-color');
    }
}

.ui-icon-activated {
    @include themify {
        color: themed('primary-active-color');
    }
}

.ui-icon-danger {
    @include themify {
        color: themed('red-color');
    }
}

.ui-icon-warning {
    @include themify {
        color: themed('orange-color');
    }
}

.ui-icon-success {
    @include themify {
        color: themed('primary-success-color');
    }
}

.ui-icon-rotate {
    transform: rotate(45deg);
}
