.ui-progress-ring {
    height: 60px;
}

.ui-progress-bar-circle-track {
    fill: transparent;
    stroke-width: 2px;

    @include themify {
        stroke: themed('primary-border-color');
    }
}

.ui-progress-bar-circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotateZ(270deg);
    stroke-width: 2px;
    transform-origin: 32px 32px;

    @include themify {
        stroke: themed('blue-color');
    }
}

.ui-progress-bar-circle-message {
    font-size: 12px;

    @include themify {
        fill: themed('blue-color');
    }
}
