.ui-form {
    display: flex;
}


.ui-form-modal {
    flex-flow: column;
}

.ui-form-options {
    display: flex;
    justify-content: center;
    width: 100%;

    .ui-button {
        margin: 0 10px;
    }
}

.ui-form-validations {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    margin-top: -10px;
    padding-bottom: 10px;
    gap: 5px;
}

.ui-form-field-full {
    margin-top: -10px;
    width: 100%;
    justify-content: center;
    display: flex;
}

.ui-form-validation {
    display: flex;
    gap: 10px;
    align-items: center;
}

.ui-form-validation-invalid {
    @include themify {
        color: themed('red-color');
    }
}

.ui-form-validation-valid {
    @include themify {
        color: themed('green-color');
    }
}
