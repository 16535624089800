.ui-menu {
    max-width: 400px !important;


    .mat-mdc-menu-content {
        padding: 0 !important;
        border: 10px;
    }

    @include themify {
        border-radius: 6px;
        box-shadow: 0 1px 5px -2px themed('primary-shadow-color');
    }
}

.ui-menu-container {
    display: flex;
    flex-flow: column;
    padding: 1px 0;
    height: 100%l;

    .ui-button {
        display: flex;
        width: 100%;
        font-weight: normal;
        justify-content: flex-start;
        padding-left: 10px;
    }

    .ui-menu-option {
        min-width: 150px;
        margin: 2px 0;
        padding: 0 10px;

        :nth-child(2) {
            margin-left: 10px;
        }
    }
}
