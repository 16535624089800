.ui-activated {
    @include themify {
        color: themed('primary-active-color');
    }
}

.ui-danger {
    @include themify {
        color: themed('dark-red-color');
    }
}

.ui-warning {
    @include themify {
        color: themed('dark-orange-color');
    }
}

.ui-success {
    @include themify {
        color: themed('primary-success-color');
    }
}

.ui-dark-activated {
    @include themify {
        color: themed('dark-blue-color');
    }
}

.ui-dark-danger {
    @include themify {
        color: themed('dark-red-color');
    }
}

.ui-dark-warning {
    @include themify {
        color: themed('dark-orange-color');
    }
}

.ui-dark-success {
    @include themify {
        color: themed('dark-green-color');
    }
}
