.ui-application-container {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
}

.ui-router-container {
    display: flex;
    flex: 1;
    height: 100%;
    padding: 10px;
    width: 100%;
    justify-content: center;

    @include themify {
        background: themed('body-background');
        color: themed('primary-text-color');
    }
}

.ui-component-container {
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 100%;
    height: 100%;
    flex-flow: column;
    padding: 10px;
}

.ui-component-body-container {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
}


.ui-header-container {
    display: flex;
    width: 100%;

    .ui-header-title-container {
        .ui-header-upper-title {
            font-size: 9px;
            font-style: italic;
        }

        .ui-header-title {
            font-weight: 600;
            font-size: 14px;
        }

        .ui-header-sub-title {
            margin-top: -5px;
            font-size: 10px;
        }
    }

    @include themify {
        color: themed('primary-text-color');
    }
}

.ui-component-header-container {
    padding: 5px;
    height: auto;
    min-height: 55px;

    .ui-header-title {
        font-size: 17px !important;
        font-weight: 700;
    }

    .ui-header-sub-title {
        padding-top: 5px;
    }
}

.ui-header-options-container {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
}

.ui-header-options-right-container {
    padding-left: 0;
    justify-content: flex-end;
}

.ui-divisor {
    height: 1px;
    width: 80%;

    @include themify {
        background: themed('primary-border-color');
    }
}

.ui-divisor-vertical {
    width: 1px;
    height: 80%;

    @include themify {
        background: themed('primary-border-color');
    }
}

.ui-empty-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    @include themify {
        color: themed('primary-text-color');
    }
}

.ui-gradient-text {
    @include themify {
        background: linear-gradient(90deg, themed('blue-color') 0%, themed('purple-color') 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.ui-empty-message {
    font-size: 15px;
}

.ui-empty-actions {
    display: flex;
    padding-top: 20px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.ui-active-text {
    @include themify {
        color: themed('primary-active-color');
    }
}

.ui-loading-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
}

.ui-loading-all-container {
    background: white;
}
