.ui-table.mat-mdc-table {
    max-width: 100%;
    margin: 0;

    .mdc-data-table__content {
        overflow-y: auto;
        @include themify {
            border-left: 1px solid themed('primary-border-color') !important;
            border-right: 1px solid themed('primary-border-color') !important;
        }

        .mat-mdc-row {
            @include themify {
                border-left: 1px solid themed('primary-border-color');
                border-right: 1px solid themed('primary-border-color');
            }
        }
    }

    @include themify {
        .mat-mdc-cell,
        .mat-mdc-header-cell {
            color: themed('primary-text-color');
        }
    }
}
