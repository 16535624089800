.ui-button {
    outline: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: normal;
    border-radius: 3px;
    padding: 5px 0;
    outline: none;
    border: none;
    background: none;
}

.ui-button:hover:not(.mat-mdc-button) {
    @include themify {
        text-shadow: 0 0 8px rgba(0, 0, 0, 0.18);
    }
}

.ui-button,
.ui-square-button,
.ui-button.mat-mdc-button {
    min-height: 30px;
    height: 30px;
    max-height: 30px;
    margin: 0;
    padding: 0 10px;
    border-radius: 3px;
    font-family: Lato !important;
    letter-spacing: unset !important;

    .mdc-button__label {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: normal;
        font-family: inherit;
        font-size: 15px;
    }

    .mdc-button__label {
        justify-content: inherit;
        display: inherit;
    }

    @include themify {
        color: themed('primary-text-color');
    }
}

.ui-button.ui-bold {
    font-weight: 600;
}

.ui-button-gradient {
    @include themify {
        background: linear-gradient(150deg, themed('purple-color') -33%, themed('blue-color') 73%);
        color: themed('white-color') !important;
    }
}

.ui-button-gradient:disabled {
    opacity: 0.2;
}

.ui-button.mat-mdc-button:disabled {
    @include themify {
        color: themed('primary-disabled-text-color');
    }
}

.ui-secondary-button {
    @include themify {
        background: themed('secondary-active-background-color') !important;
        color: themed('secondary-active-text-color') !important;
    }
}

.ui-text-button:disabled,
.ui-secondary-button:disabled {
    opacity: 0.4;
}

.ui-square-button.mat-mdc-button {
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    min-width: 40px;
    font-size: 25px;
    border-radius: 3px;

    @include themify {
        border: 1px solid themed('primary-border-color');
    }
}

.ui-circle-button.mat-mdc-button {
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 20px;
    font-size: 25px;
}

.ui-circle-button:disabled {
    opacity: 0.4;
}

.ui-text-button {
    cursor: pointer;
    text-decoration: none;

    .ui-button-label {
        font-weight: 700;
        font-size: 16px;
    }

    @include themify {
        color: themed('primary-active-color');
    }
}

a.ui-text-button:hover {
    text-decoration: underline;
}
