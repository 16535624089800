.ui-panel-error {
    display: flex;
    padding: 10px;
    max-width: 340px;
    justify-content: center;
    border-radius: 8px;
    align-items: center;
    margin-bottom: 10px;
    font-size: 13px;

    @include themify {
        background: themed('red-color');
        color: themed('white-color');
    }
}

.ui-panel-message-bold {
    font-weight: 500;
    font-size: 15px;
}

.ui-panel-message-regular {
    font-weight: 400;
    font-size: 13px;
    margin-left: 5px;
}

.ui-panel-bordered {
    position: relative;

    @include themify {
        border-radius: 4px;
        border: 1px solid themed('primary-border-color');
    }
}


.ui-panel-bordered-title {
    position: absolute;
    top: -8px;
    left: 20px;
    padding: 0 5px;

    @include themify {
        background: themed('primary-background-color');
        color: themed('primary-text-color');
    }
}
