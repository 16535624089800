
.ui-paginator-container {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    .ui-circle-button {
        font-size: 14px;
    }

    @include themify {
        color: themed('primary-text-color')
    }
}

.ui-paginator-sizes {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 0 20px 0 0;

    .ui-form-field-small {
        padding: 0 10px;
        max-width: 80px;
    }
}

.ui-paginator-sizes-label {
    font-weight: 600;
    font-size: 12px;
}

.ui-paginator-options {
    display: flex;
    align-items: center;
    padding: 0 20px;

    .ui-circle-button {
        margin: 0 5px;
    }
}
