.ui-list-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    overflow-y: auto;
}

.ui-list-header-secondary-container,
.ui-list-header-container {
    display: flex;
    width: 100%;
    padding: 0 5px;
}

.ui-list-header-secondary-container {
    height: 50px;

    .ui-list-header {
        max-height: 50px;
        min-height: 50px;

        @include themify {
            background: themed('secondary-background-color');
        }
    }
}

.ui-list-header {
    display: flex;
    align-items: center;
    border-radius: 3px;
    font-weight: 500;
    width: 100%;
    height: 100%;
    padding: 0 20px;

    @include themify {
        color: themed('secondary-text-color');
    }
}

.ui-list-header-column {
    flex: 1;
    display: flex;
    justify-content: flex-start;
}

.ui-list-rows-container {
    height: 100%;
    overflow-y: auto;
    padding: 0 5px 5px 5px;
    margin-top: 8px;
}

.ui-list-row-container {
    display: flex;
    flex-flow: column;
    min-height: 80px;
    justify-content: center;
    align-items: center;
}

.ui-list-row-container-expanded {
    .ui-list-row {
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
        border-bottom: unset !important;
    }
}

.ui-list-row {
    display: flex;
    height: 80px;
    width: 100%;
    padding: 0 20px;
    margin-top: 13px;
    border-radius: 6px;
    align-items: center;
    cursor: pointer;

    @include themify {
        background: themed('primary-background-color');
        color: themed('primary-text-color');
        box-shadow: 0px 0px 3px -1px themed('primary-shadow-color');
    }
}

.ui-list-row:hover {
    @include themify {
        box-shadow: 1px 1px 7px -2px themed('primary-shadow-color');
    }
}

.ui-list-row-detail-expanded {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 10px 20px;
    border-radius: 3px;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    cursor: pointer;

    @include themify {
        background: themed('secondary-background-color');
        border: 1px solid themed('primary-border-color');
        border-top: unset;
        color: themed('secondary-text-color');
    }
}

.ui-list-row-detail-expanded:hover {
    @include themify {
        box-shadow: 0 1px 2px -1px themed('primary-shadow-color');
    }
}

.ui-list-badge {
    width: 100px;
}

.ui-list-row-column,
.ui-list-header-column {
    display: flex;
    flex-flow: column;
    justify-content: center;
    flex: 1;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .ui-list-link {
        font-weight: 500;
        font-size: 15px;
    }

    .ui-list-label {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
    }

    .ui-list-sub-label {
        margin-top: 5px;
        font-weight: 500;
        font-size: 12px;
    }

    .ui-list-icon {
        font-size: 17px;
        @include themify {
            color: themed('primary-active-color');
        }
    }
}

.ui-list-row-column-small {
    flex: unset;
    width: 100px;
}

.ui-list-options {
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: flex-end;
}
