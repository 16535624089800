.ui-form-field.mat-mdc-slide-toggle {
    width: auto !important;

    .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
        @include themify {
            background: themed('primary-active-color');
            opacity: 0.9;
        }
    }

    .mdc-switch__track::after {
        @include themify {
            background: themed('primary-active-color');
            opacity: 0.8;
        }
    }

    .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after,
    .mdc-switch:enabled:active .mdc-switch__track::after,
    .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after {
        @include themify {
            background: themed('primary-active-color');
            opacity: 1;
        }
    }

    .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
        @include themify {
            background: themed('primary-active-color');
            opacity: 1;
        }
    }

    .mdc-switch.mdc-switch--unselected:enabled:focus:not(:active) .mdc-switch__handle::after,
    .mdc-switch.mdc-switch--unselected:enabled:active .mdc-switch__handle::after,
    .mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
        @include themify {
            background: themed('primary-active-color');
        }
    }

    .mdc-label {
        margin-left: 10px;
    }
}
