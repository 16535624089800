.ui-title {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;

    @include themify {
        color: themed('primary-text-color');
    }
}

.ui-sub-title {
    font-size: 11px;
    line-height: 12px;

    @include themify {
        color: themed('primary-text-color');
    }
}
