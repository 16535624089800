.ui-tab-labels-left {
    .mat-mdc-tab-labels {
        justify-content: flex-start !important;
    }
}

.ui-tab-container {
    .mdc-tab__text-label {
        font-family: Lato !important;

        @include themify {;
            color: themed('primary-text-color') !important;
            font-weight: 400;
        }
    }

    .mdc-tab--active {
        .mdc-tab-indicator {
            top: -10px;
        }

        .mdc-tab__text-label {
            @include themify {
                font-weight: 550;
                color: themed('primary-active-color') !important;
            }
        }

        .mdc-tab-indicator__content--underline {
            width: 60%;

            @include themify {
                border-color: themed('primary-active-color') !important;
            }
        }
    }
}
