.ui-form-field.mat-mdc-form-field {
    .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-floating-label,
    .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-floating-label--float-above {
        @include themify {
            color: themed('primary-text-color');
            opacity: 0.8;
        }
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-floating-label,
    .mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above,
    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
        @include themify {
            color: themed('primary-text-color');
            opacity: 1;
        }
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
        @include themify {
            border-bottom-color: themed('primary-text-color');
            opacity: 0.8;
        }
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-line-ripple::before {
        @include themify {
            border-bottom-color: themed('primary-text-color');
            opacity: 1;
        }
    }
}

.ui-form-field.mat-mdc-form-field:not(.ui-field-boxed-field,.ui-field-search) {
    .mat-mdc-text-field-wrapper {
        height: 47px;
        background: unset;
        padding: 5px;
    }

    .mat-mdc-form-field-focus-overlay {
        background: unset;
    }

    .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
        top: calc(var(--mat-form-field-container-height) / 2 - 2px) !important;
    }

    .mat-mdc-text-field-wrapper:hover {
        background: unset;
    }

    .mat-mdc-form-field-infix {
        margin-top: -5px;
    }

    .mdc-line-ripple::after {
        border-bottom-color: blue;
        @include themify {
            border-bottom-color: themed('primary-active-color') !important;
        }
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        @include themify {
            .mdc-line-ripple::after {
                    border-bottom-color: themed('primary-active-color') !important;
            }
        }
    }
}

.ui-field-boxed-field.mat-mdc-form-field:not(.ui-field-search) {
    .mdc-line-ripple::after {
        border-bottom-color: blue;
        @include themify {
            border-bottom-color: themed('primary-active-color') !important;
        }
    }
    .mdc-text-field--filled:not(.mdc-text-field--disabled){
        @include themify {
            background: themed('tertiary-background-color') !important;

            .mdc-line-ripple::after {
                border-bottom-color: themed('primary-active-color') !important;
            }
        }
    }
}

.ui-field-search.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
        border-radius: 10px !important;
        height: 30px;
    }

    .mdc-line-ripple::before,
    .mdc-line-ripple::after {
        border: unset !important;
    }
    .mdc-text-field--filled:not(.mdc-text-field--disabled){
        @include themify {
            background: themed('secondary-background-color') !important;
        }
    }
}

.ui-autocomplete-option-container {
    .mdc-list-item__primary-text {
        display: flex !important;
        flex-flow: column;
        justify-content: center;
        text-align: left;

        :nth-child(1) {
            font-size: 15px;
            text-align: left;
            line-height: 15px;
        }

        :nth-child(2) {
            padding-top: 3px;
            font-size: 10px;
            line-height: 10px;
            font-weight: 500;
        }
    }
}

.ui-form-field-small.mat-mdc-form-field {
    height: 40px;

    .mat-mdc-text-field-wrapper {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 0 10px;
    }

    .mat-mdc-form-field-infix {
        padding-top: 8px;
    }

    .mat-mdc-form-field-subscript-wrapper {
        height: 10px;
    }

    .mdc-floating-label.mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
        top: 14px !important;
        font-size: 15px;
    }

    .mdc-floating-label--float-above {
        top: 18px !important;
        font-size: 13px;
    }

    .mat-mdc-input-element {
        height: 20px;
        font-size: 14px;
    }
}
