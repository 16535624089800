.ui-user-selector-container {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    height: 100%;

    .ui-user-detail-container {
        display: flex;
        flex-flow: column;
        gap: 3px;
        flex: 1;
    }

    @include themify {
        background: themed('primary-background-color');
        border: 1px solid themed('primary-border-color');
        border-radius: 5px;
    }
}

.ui-user-selector-container:hover {
    @include themify {
        background: themed('tertiary-background-color');
    }
}

.ui-users-menu-header-container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    font-weight: 500;
    padding: 10px 20px 0 10px;
    z-index: 1;

    .ui-title {
        margin-left: 10px;
        padding-bottom: 10px;
        font-size: 15px;
    }

    .ui-form-field-small {
        width: 100%;
    }
}

.ui-users-menu-container {
    display: flex;
    flex-flow: column;
    padding: 0 10px 10px 10px;
    width: 300px;

    .ui-form-field-small {
        position: relative;
    }

    .ui-spinner-menu-container {
        position: absolute;
        top: 5px;
        right: 1px;
    }

    .ui-user-menu-container {
        display: flex;
        width: 100%;
        background: unset;
        padding: 10px;
        margin: 5px 0;
        flex-flow: row;
        cursor: pointer;
        border-radius: 6px;
        outline: none;
        text-align: left;
        align-items: center;
    }

    .ui-user-menu-container:disabled {
        opacity: 0.4;
        background: inherit;
    }

    .ui-user-menu-information {
        display: flex;
        flex-flow: column;
        flex: 1;
        justify-content: center;
    }

    @include themify {

        .ui-user-menu-container {
            background: themed('primary-background-color');
            border: 1px solid themed('primary-border-color');
        }

        .ui-user-menu-container:not(.ui-user-menu-selected):hover {
            border: 1px solid themed('primary-active-color');
        }

        .ui-user-menu-selected {
            border: 2px solid themed('primary-active-color');
        }
    }
}

.ui-user-photo {
    display: flex;
    align-items: center;
    border-radius: 100%;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 0 20px 0 10px;

    img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        object-fit: cover;
    }

    span {
        font-size: 20px;
    }

    @include themify {
        border: 1px solid themed('primary-border-color');
    }
}
